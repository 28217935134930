.badge {
	font-weight: 500 !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	padding: 3px 5px 4px !important;
}
.badge-light {
	background-color: $gray-300;
}
.badge-pill {
	padding-left: 8px;
	padding-right: 8px;
}
/*////////////////////badgelight//////////////////*/

.badge-success-transparent {
	background-color: rgb(212, 242, 225) !important;
	color: $success;
}
.badge-teal-transparent {
	background-color: #d2f5f5 !important;
	color: $teal;
}
.badge-warning-transparent {
	background-color: rgba(255, 193, 7, 0.15) !important;
	color: $warning;
}
.badge-danger-transparent {
	color: $danger;
	background-color: #f9e2e2;
}
.badge-purple-transparent {
	color: $purple;
	background-color: #e1defe;
}
.badge-info-transparent {
	color: #0a7ffb;
	background-color: rgba(3, 85, 208, 0.1);
}
.badge-pink-transparent {
	color: $pink;
	background-color: rgb(254, 229, 241);
}
.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1.3;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 3px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.badge {
		transition: none;
	}
}
a.badge:hover, a.badge:focus {
	text-decoration: none;
}
.badge:empty {
	display: none;
}
.btn .badge, .sp-container button .badge {
	position: relative;
	top: -1px;
}
.badge-pill {
	padding-right: 0.6em;
	padding-left: 0.6em;
	border-radius: 10rem;
}
.badge-secondary {
	color: $white;
	background-color: $secondary;
}
a.badge-secondary:hover, a.badge-secondary:focus {
	color: $white;
	background-color: $secondary;
}
a.badge-secondary:focus, a.badge-secondary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}
.badge-success {
	color: $white;
	background-color: $success;
}
a.badge-success:hover, a.badge-success:focus {
	color: $white;
	background-color: #2a7d01;
}
a.badge-success:focus, a.badge-success.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}
.badge-info {
	color: $white;
	background-color: $info;
}
a.badge-info:hover, a.badge-info:focus {
	color: $white;
	background-color: $info;
}
a.badge-info:focus, a.badge-info.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
	color: $white;
	background-color: $warning;
}
a.badge-warning:hover, a.badge-warning:focus {
	color: $gray-900;
	background-color: #d39e00;
}
.badge-purple {
	color: $white;
	background-color: 7571f9;
}
a.badge-purple:hover, a.badge-purple:focus {
	color: $white;
	background-color: #6536bb;
}
a.badge-purple:focus, a.badge-purple.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(101, 54, 187, 0.5);
}
.badge-teal {
	color: $white;
	background-color: $teal;
}
a.badge-teal:hover, a.badge-teal:focus {
	color: $gray-900;
	background-color: $teal;
}
a.badge-warning:focus, a.badge-warning.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
	color: $white;
	background-color: $danger;
}
a.badge-danger:hover, a.badge-danger:focus {
	color: $white;
	background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-pink {
	color: $white;
	background-color: $pink;
}
a.badge-pink:hover, a.badge-pink:focus {
	color: $white;
	background-color: #e20b73;
}
a.badge-pink:focus, a.badge-pink.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(241, 0, 117, 0.5);
}
.badge-purple {
	color: $white;
	background-color: $purple;
}
a.badge-purple:hover, a.badge-purple:focus {
	color: $white;
	background-color: #582ea2;
}
a.badge-purple:focus, a.badge-purple.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.5);
}
.badge-light {
	color: $gray-900;
	background-color: $gray-100;
}
a.badge-light:hover, a.badge-light:focus {
	color: $gray-900;
	background-color: #d5d9e4;
}
a.badge-light:focus, a.badge-light.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}
.badge-dark {
	color: $white;
	background-color: $gray-800;
}
a.badge-dark:hover, a.badge-dark:focus {
	color: $white;
	background-color: #283143;
}
a.badge-dark:focus, a.badge-dark.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}
@media print {
	.badge {
		border: 1px solid $black;
	}
}