.btn, .sp-container button {
	display: inline-block;
	font-weight: 400;
	color: $default-color;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 3px;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	box-shadow: none;
	outline: 0;
	height: 38px;
}
@media (prefers-reduced-motion: reduce) {
	.btn, .sp-container button {
		transition: none;
	}
}
.btn:hover, .sp-container button:hover {
	color: $default-color;
	text-decoration: none;
}
.btn:focus, .sp-container button:focus, .btn.focus, .sp-container button.focus {
	outline: 0;
	box-shadow: none;
}
.btn.disabled, .sp-container button.disabled, .btn:disabled, .sp-container button:disabled {
	opacity: 0.65;
}
a.btn.disabled, fieldset:disabled a.btn {
	pointer-events: none;
}
.btn-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
	&:hover {
		color: $white;
		background-color: #7e40c7;
		border-color: #7e40c7;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #7e40c7;
		border-color: #7e40c7;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
		opacity: 0.5;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}
.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
}
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-success {
	color: $white;
	background-color: $success;
	border-color: $success;
	&:hover {
		color: $white;
		background-color: #0ec187;
		border-color: #0ec187;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #0ec187;
		border-color: #0ec187;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $success;
		border-color: $success;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #0ec187;
			border-color: #0ec187;
		}
	}
}
.show>.btn-success.dropdown-toggle {
	color: $white;
	background-color: #0ec187;
	border-color: #0ec187;
}
.btn-success:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
		color: $white;
		background-color: $success;
		border-color: $success;
	}
}
.show>.btn-success.dropdown-toggle:focus {
	box-shadow: none;
	color: $white;
	background-color: $success;
	border-color: $success;
}
.btn-info {
	color: $white;
	background-color: $info;
	border-color: $info;
	&:hover {
		color: $white;
		background-color: #1dbedc;
		border-color: #1dbedc;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #1dbedc;
		border-color: #1dbedc;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $info;
			border-color: $info;
		}
	}
}
.show>.btn-info.dropdown-toggle {
	color: $white;
	background-color: $info;
	border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
		color: $white;
		background-color: $info;
		border-color: $info;
	}
}
.show>.btn-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-warning {
	color: $white;
	background-color: $warning;
	border-color: $warning;
	&:hover {
		color: $white;
		background-color: #fb8e09;
		border-color: #fb8e09;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #fb8e09;
		border-color: #fb8e09;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $warning;
		border-color: $warning;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #fb8e09;
			border-color: #fb8e09;
		}
	}
}
.show>.btn-warning.dropdown-toggle {
	color: $white;
	background-color: #fb8e09;
	border-color: #fb8e09;
}
.btn-warning:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
		color: $white;
		background-color: #fb8e09;
		border-color: #fb8e09;
	}
}
.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-danger {
	color: $white !important;
	background-color: $danger;
	border-color: $danger;
	&:hover {
		color: $white;
		background-color: #f95374;
		border-color: #f95374;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #f95374;
		border-color: #f95374;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $danger;
			border-color: $danger;
		}
	}
}
.show>.btn-danger.dropdown-toggle {
	color: $white;
	background-color: $danger;
	border-color: $danger;
}
.btn-danger:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
}
.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-teal {
	color: $white;
	background-color: $teal;
	border-color: #05d6d6;
	&:hover {
		color: $white;
		background-color: #04bfbf;
		border-color: #04bfbf;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #04bfbf;
		border-color: #04bfbf;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #04bfbf;
		border-color: #04bfbf;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #04bfbf;
			border-color: #04bfbf;
		}
	}
}
.show>.btn-teal.dropdown-toggle {
	color: $white;
	background-color: #04bfbf;
	border-color: #04bfbf;
}
.btn-teal:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-teal.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-light {
	color: $gray-900;
	background-color: $gray-100;
	border-color: $gray-100;
	&:hover {
		color: $gray-900;
		background-color: #e9eaf1;
		border-color: #e9eaf1;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $gray-900;
		background-color: #e9eaf1;
		border-color: #e9eaf1;
	}
	&.disabled, &:disabled {
		color: $gray-900;
		background-color: $gray-100;
		border-color: $gray-100;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $gray-900;
			background-color: $gray-200;
			border-color: $gray-200;
		}
	}
}
.show>.btn-light.dropdown-toggle {
	color: $gray-900;
	background-color: $gray-200;
	border-color: $gray-200;
}
.btn-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-light.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-dark {
	color: $white;
	background-color: $gray-900;
	border-color: $gray-900;
	&:hover {
		color: $white;
		background-color: #383e5a;
		border-color: #383e5a;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #383e5a;
		border-color: #383e5a;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $gray-800;
		border-color: $gray-800;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $gray-900;
			border-color: $gray-900;
		}
	}
}
.show>.btn-dark.dropdown-toggle {
	color: $white;
	background-color: $gray-900;
	border-color: $gray-900;
}
.btn-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-secondary {
	color: $secondary;
	border-color: $secondary;
	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&.disabled, &:disabled {
		color: $secondary;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}
.show>.btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-outline-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: $success;
	border-color: $success;
	&:hover {
		color: $white;
		background-color: $success;
		border-color: $success;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $success;
		border-color: $success;
	}
	&.disabled, &:disabled {
		color: $success;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $success;
			border-color: $success;
		}
	}
}
.show>.btn-outline-success.dropdown-toggle {
	color: $white;
	background-color: $success;
	border-color: $success;
}
.btn-outline-success:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: $info;
	border-color: $info;
	&:hover {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&.disabled, &:disabled {
		color: $info;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $info;
			border-color: $info;
		}
	}
}
.show>.btn-outline-info.dropdown-toggle {
	color: $white;
	background-color: $info;
	border-color: $info;
}
.btn-outline-info:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: $warning;
	border-color: $warning;
	&:hover {
		color: $white;
		background-color: $warning;
		border-color: $warning;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $warning;
		border-color: $warning;
	}
	&.disabled, &:disabled {
		color: $warning;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $warning;
			border-color: $warning;
		}
	}
}
.show>.btn-outline-warning.dropdown-toggle {
	color: $white;
	background-color: $warning;
	border-color: $warning;
}
.btn-outline-warning:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: $danger;
	border-color: $danger;
	&:hover {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
	&.disabled, &:disabled {
		color: $danger;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $danger;
			border-color: $danger;
		}
	}
}
.show>.btn-outline-danger.dropdown-toggle {
	color: $white;
	background-color: $danger;
	border-color: $danger;
}
.btn-outline-danger:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
	color: $gray-100;
	border-color: $gray-100;
	&:hover {
		color: $gray-900;
		background-color: $gray-100;
		border-color: $gray-100;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $gray-900;
		background-color: $gray-100;
		border-color: $gray-100;
	}
	&.disabled, &:disabled {
		color: $gray-100;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $gray-900;
			background-color: $gray-100;
			border-color: $gray-100;
		}
	}
}
.show>.btn-outline-light.dropdown-toggle {
	color: $gray-900;
	background-color: $gray-100;
	border-color: $gray-100;
}
.btn-outline-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-dark {
	color: $gray-900;
	border-color: $gray-900;
	&:hover {
		color: $white;
		background-color: $gray-900;
		border-color: $gray-900;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $gray-900;
		border-color: $gray-900;
	}
	&.disabled, &:disabled {
		color: $gray-900;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $gray-900;
			border-color: $gray-900;
		}
	}
}
.show>.btn-outline-dark.dropdown-toggle {
	color: $white;
	background-color: $gray-900;
	border-color: $gray-900;
}
.btn-outline-dark:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-link {
	font-weight: 400;	
	text-decoration: none;
	&:hover {
		color: #8485fb;
		text-decoration: none;
	}
	&:focus, &.focus {
		text-decoration: none;
		box-shadow: none;
	}
	&:disabled, &.disabled {
		color: $gray-600;
		pointer-events: none;
	}
}
.btn-lg, .btn-group-lg>.btn, .sp-container .btn-group-lg>button {
	padding: 0.5rem 1rem;
	font-size: 1.09375rem;
	line-height: 1.5;
	border-radius: 0.3rem;
	height: 44px;
}
.btn-sm, .btn-group-sm>.btn, .sp-container .btn-group-sm>button {
	padding: 0.25rem 0.5rem;
	font-size: 0.76562rem;
	line-height: 1.5;
	border-radius: 0.2rem;
	height: 30px;
	line-height: 24px !important;
}
.btn-block {
	display: block;
	width: 100%;
	+.btn-block {
		margin-top: 0.5rem;
	}
}
input {
	&[type="submit"].btn-block, &[type="reset"].btn-block, &[type="button"].btn-block {
		width: 100%;
	}
}
.btn-group, .btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group>.btn, .sp-container .btn-group>button, .btn-group-vertical>.btn, .sp-container .btn-group-vertical>button {
	position: relative;
	flex: 1 1 auto;
}
.btn-group>.btn:hover, .sp-container .btn-group>button:hover, .btn-group-vertical>.btn:hover, .sp-container .btn-group-vertical>button:hover, .btn-group>.btn:focus, .sp-container .btn-group>button:focus, .btn-group>.btn:active, .sp-container .btn-group>button:active, .btn-group>.btn.active, .sp-container .btn-group>button.active, .btn-group-vertical>.btn:focus, .sp-container .btn-group-vertical>button:focus, .btn-group-vertical>.btn:active, .sp-container .btn-group-vertical>button:active, .btn-group-vertical>.btn.active, .sp-container .btn-group-vertical>button.active {
	z-index: 1;
}
.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}
.btn-group>.btn:not(:first-child), .sp-container .btn-group>button:not(:first-child) {
	margin-left: -1px;
}
.btn-group> {
	.btn-group:not(:first-child) {
		margin-left: -1px;
	}
	.btn:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
.sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle), .btn-group>.btn-group:not(:last-child)>.btn, .sp-container .btn-group>.btn-group:not(:last-child)>button {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn-group>.btn:not(:first-child), .sp-container .btn-group>button:not(:first-child), .btn-group>.btn-group:not(:first-child)>.btn, .sp-container .btn-group>.btn-group:not(:first-child)>button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split, .sp-container .btn-group-sm>button+.dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}
.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split, .sp-container .btn-group-lg>button+.dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}
.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	>.btn {
		width: 100%;
	}
}
.sp-container .btn-group-vertical>button {
	width: 100%;
}
.btn-group-vertical> {
	.btn-group {
		width: 100%;
	}
	.btn:not(:first-child) {
		margin-top: -1px;
	}
}
.sp-container .btn-group-vertical>button:not(:first-child) {
	margin-top: -1px;
}
.btn-group-vertical> {
	.btn-group:not(:first-child) {
		margin-top: -1px;
	}
	.btn:not(:last-child):not(.dropdown-toggle) {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}
.sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle), .btn-group-vertical>.btn-group:not(:last-child)>.btn, .sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.btn-group-vertical>.btn:not(:first-child), .sp-container .btn-group-vertical>button:not(:first-child), .btn-group-vertical>.btn-group:not(:first-child)>.btn, .sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.btn-group-toggle>.btn, .sp-container .btn-group-toggle>button, .btn-group-toggle>.btn-group>.btn, .sp-container .btn-group-toggle>.btn-group>button {
	margin-bottom: 0;
}
.btn-group-toggle>.btn input[type="radio"], .sp-container .btn-group-toggle>button input[type="radio"], .btn-group-toggle>.btn input[type="checkbox"], .sp-container .btn-group-toggle>button input[type="checkbox"], .btn-group-toggle>.btn-group>.btn input[type="radio"], .sp-container .btn-group-toggle>.btn-group>button input[type="radio"], .btn-group-toggle>.btn-group>.btn input[type="checkbox"], .sp-container .btn-group-toggle>.btn-group>button input[type="checkbox"] {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}
/* ###### 3.3 Buttons ###### */

.btn, .sp-container button {
	border-width: 0;
	line-height: 1.538;
	padding: 9px 20px;
	transition: none;
}
.btn:active, .sp-container button:active, .btn:focus, .sp-container button:focus {
	box-shadow: none;
}
.btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
	&:hover {
		color: $white;
		background-color: $indigo;
		border-color: $indigo;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $indigo;
		border-color: $indigo;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: #b057bf;
		border-color: #b057bf;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #b057bf;
			border-color: #b057bf;
		}
	}
}
.show>.btn-indigo.dropdown-toggle {
	color: $white;
	background-color: #b057bf;
	border-color: #b057bf;
}
.btn-indigo:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-indigo.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-purple {
	color: $white;
	background-color: $purple;
	border-color: #643ab0;
	&:hover {
		color: $white;
		background-color: #5e37a6;
		border-color: #4e2d89;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: #5e37a6;
		border-color: #4e2d89;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $purple;
		border-color: #643ab0;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: #59339d;
			border-color: #482a80;
		}
	}
}
.show>.btn-purple.dropdown-toggle {
	color: $white;
	background-color: #59339d;
	border-color: #482a80;
}
.btn-purple:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-purple.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-pink {
	color: $white;
	background-color: $pink;
	border-color: $pink;
	&:hover {
		color: $white;
		background-color: $pink;
		border-color: $pink;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $pink;
		border-color: $pink;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $pink;
		border-color: $pink;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $pink;
			border-color: $pink;
		}
	}
}
.show>.btn-pink.dropdown-toggle {
	color: $white;
	background-color: $pink;
	border-color: $pink;
}
.btn-pink:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-pink.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-gray-500 {
	color: $gray-900;
	background-color: $gray-500;
	border-color: $gray-500;
	&:hover {
		color: $white;
		background-color: $gray-600;
		border-color: $gray-600;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $gray-600;
		border-color: $gray-600;
	}
	&.disabled, &:disabled {
		color: $gray-900;
		background-color: $gray-500;
		border-color: $gray-500;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $gray-500;
			border-color: $gray-500;
		}
	}
}
.show>.btn-gray-500.dropdown-toggle {
	color: $white;
	background-color: $gray-500;
	border-color: $gray-500;
}
.btn-gray-500:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-gray-500.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-gray-700 {
	color: $white;
	background-color: $gray-700;
	border-color: $gray-700;
	&:hover {
		color: $white;
		background-color: $gray-800;
		border-color: $gray-800;
	}
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $gray-800;
		border-color: $gray-800;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $gray-700;
		border-color: #4f5c73;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $gray-700;
			border-color: $gray-700;
		}
	}
}
.show>.btn-gray-700.dropdown-toggle {
	color: $white;
	background-color: $gray-800;
	border-color: $gray-800;
}
.btn-gray-700:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-gray-700.dropdown-toggle:focus {
	box-shadow: none;
}
[class*=btn-outline-] {
	padding: 0.4rem 1rem;
	line-height: 1.657;
	border-width: 1px;
	background-color: transparent;
	&:hover, &:focus {
		color: $white;
	}
}
.btn-outline-indigo {
	border-color: #b057bf;
	color: #b057bf;
	&:hover, &:focus {
		background-color: #b057bf;
		border-color: #b057bf;
		color: $white;
	}
}
.btn-outline-light {
	border-color: $gray-100;
	color: $default-color;
	&:hover, &:focus {
		background-color: $gray-100;
		border-color: $gray-100;
		color: $default-color;
	}
}
/* ###### 5.1 Buttons  ###### */

.btn-rounded {
	border-radius: 50px;
}
.btn-with-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
	i {
		line-height: 0;
		font-size: 20px;
		margin-right: 5px;
		&.typcn {
			line-height: .9;
		}
		&.fas {
			font-size: 15px;
		}
		&.icon {
			font-size: 18px;
		}
	}
}
.btn-icon {
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	i {
		line-height: 0;
		font-size: 1.2rem;
		&.typcn {
			line-height: .95;
		}
	}
}
.btn-icon-list {
	display: flex;
	align-items: center;
	.btn+.btn, .sp-container button+.btn {
		margin-left: 5px;
	}
}
.sp-container .btn-icon-list button+.btn, .btn-icon-list .sp-container .btn+button, .sp-container .btn-icon-list .btn+button, .btn-icon-list .sp-container button+button, .sp-container .btn-icon-list button+button {
	margin-left: 5px;
}
@media (max-width: 991px) {
	.btn.btn-default.nav-link {
		margin-top: 0;
	}
}
.nav-link#bs-example-navbar-collapse-1 {
	padding: 0.6rem 0.3rem;
}
/* ###### 4.8 button-light ###### */

.btn-warning-light {
	color: #FDAC41;
	background-color: rgba(253, 172, 65, 0.2);
	border-color: rgba(253, 172, 65, 0.2);
	&:hover {
		color: $gray-900;
		background-color: #FDAC41;
		border-color: #FDAC41;
	}
}
.btn-warning {
	&:focus, &.focus {
		box-shadow: none;
		color: $white;
		background-color: $warning;
		border-color: $warning;
	}
}
.btn-warning-light {
	&.disabled, &:disabled {
		color: $gray-900;
		background-color: #FDAC41;
		border-color: #FDAC41;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $gray-900;
			background-color: #FDAC41;
			border-color: #FDAC41;
		}
	}
}
.show>.btn-warning-light.dropdown-toggle {
	color: $gray-900;
	background-color: #FDAC41;
	border-color: #FDAC41;
}
.btn-warning-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-warning-light.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-white {
	background-color: $white;
	background-image: none;
	border-color: #e7e9f1;
	color: $default-color;
	&:hover {
		background-color: $white;
		background-image: none;
		border-color: #e7e9f1;
	}
	&:focus, &.focus {
		box-shadow: none;
		background-color: $white;
		background-image: none;
		border-color: #e7e9f1;
	}
	&.disabled, &:disabled {
		color: #495057;
		background-color: $white;
		border-color: $white;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: #495057;
			background-color: #eff0f6;
			border-color: #e7e9f1;
		}
	}
}
.show>.btn-white.dropdown-toggle {
	color: #495057;
	background-color: #eff0f6;
	border-color: #e7e9f1;
}
.btn-white:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: none;
	}
}
.show>.btn-white.dropdown-toggle:focus {
	box-shadow: none;
}