.toast {
	background-color: rgba(255, 255, 255, 0.95);
	border-color: rgba(28, 39, 60, 0.16);
	box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
	border-radius: 3px;
}
.toast-header {
	border-bottom-color: rgba(205, 212, 224, 0.4);
	.close {
		font-weight: 300;
		color: $gray-500;
	}
}
.toast {
	max-width: 350px;
	overflow: hidden;
	font-size: 0.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid $border;
	box-shadow: none;
	opacity: 0;
	border-radius: 0.25rem;
	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}
	&.showing {
		opacity: 1;
	}
	&.show {
		display: block;
		opacity: 1;
	}
	&.hide {
		display: none;
	}
}
.toast-header {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	color: $gray-900;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid $black-05;
}
.toast-body {
	padding: 0.75rem;
	font-size: 12px;
}
.demo-static-toast .toast {
	opacity: 1;
}