/* ###### 3.11 Pagination ###### */

.page-item {
	&:first-child .page-link, &:last-child .page-link {
		border-radius: 0;
	}
	+.page-item {
		margin-left: 0px;
	}	
}
.page-link {
	color: $gray-700;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 38px;
	border-width: 0;
	background-color: $gray-200;
	margin-left: 0;
	font-weight: 400;
	i {
		font-size: 18px;
		line-height: 0;
	}
	&:hover, &:focus {
		color: $gray-900;
		background-color: $gray-300;
	}
}
.pagination-dark {
	.page-item.active .page-link {
		background-color: $white-5;
		color: $white;
		border-color: $white-1;
	}
	.page-item {
		&:first-child .page-link {
			border-left: 1px solid $white-1;
		}
	}
	.page-link {
		font-weight: 400;
		background-color: $white-05;
		color: $white-8;
		border: 1px solid $white-1;
		border-left: 0;
		&:hover, &:focus {
			color: $white;
			border-color: $white-1;
			background-color: $white-08;
		}
	}
}
.pagination-success .page-item.active .page-link {
	background-color: $success;
	border-color: $success;
}
.pagination-circled {
	.page-item {
		+.page-item {
			margin-left: 5px;
		}
		&:first-child .page-link, &:last-child .page-link {
			border-radius: 100%;
		}
	}
	.page-link {
		padding: 0;
		width: 40px;
		border-radius: 100%;
	}
}
.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
}
.page-link {
	position: relative;
	display: flex;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #596882;
	background-color: $gray-100;
	border: 1px solid $border;
	&:hover {
		z-index: 2;
		color: #8485fb;
		text-decoration: none;
		background-color: $gray-100;
		border-color: $border;
	}
	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: none;
	}
}
.page-item {
	&:first-child .page-link {
		margin-left: 0;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}	
	&.disabled .page-link {
		color: $gray-600;
		pointer-events: none;
		cursor: auto;
		background-color: $white;
		border-color: $border;
	}
}
.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.09375rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 0.3rem;
			border-bottom-left-radius: 0.3rem;
		}
		&:last-child .page-link {
			border-top-right-radius: 0.3rem;
			border-bottom-right-radius: 0.3rem;
		}
	}
}
.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.76562rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 0.2rem;
			border-bottom-left-radius: 0.2rem;
		}
		&:last-child .page-link {
			border-top-right-radius: 0.2rem;
			border-bottom-right-radius: 0.2rem;
		}
	}
}
.page-prev .page-link, .page-next .page-link {
	width: auto !important;
	padding: 0 10px;
}
.page-prev .page-link, .page-next .page-link {
	width: auto !important;
	padding: 0 10px;
}