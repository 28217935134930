/* ###### 7.6 Mail  ###### */

.main-content-mail {
	.container, .container-fluid {
		padding: 20px 0 0;
	}
}
@media (min-width: 992px) {
	.main-content-mail {
		.container, .container-fluid {
			padding: 0;
		}
	}
}
@media (min-width: 992px) {
	.main-content-left-mail {
		display: block;
	}
}
.main-content-left-mail .btn-compose {
	margin-bottom: 20px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	padding: 0 20px;
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
}
@media (min-width: 992px) {
	.main-content-left-mail .btn-compose {
		margin-right: 0;
	}
}
.main-mail-menu {
	position: relative;
	padding-right: 0;
	.nav-link {
		height: 40px;
		i {
			font-size: 24px;
		}
	}
}
@media (min-width: 992px) {
	.main-mail-menu {
		padding-right: 0;
	}
}
@media (min-width: 992px) {
	.main-mail-menu .nav-link {
		font-size: 14px;
	}
}
.main-mail-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 0 20px;
	>div {
		&:first-child p {
			font-size: 13px;
			margin-bottom: 0;
		}
		&:last-child {
			display: none;
			>span {
				font-size: 12px;
				margin-right: 10px;
			}
		}
	}
	.btn-group {
		.btn, .sp-container button {
			font-size: 21px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 38px;
			height: 38px;
			line-height: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			color: $gray-500;
			border-color: $gray-300;
			background-color: $white;
		}
	}
}
@media (min-width: 992px) {
	.main-mail-header {
		padding: 0px;
		margin-bottom: 1.25rem;
	}
}
@media (min-width: 768px) {
	.main-mail-header>div:last-child {
		display: flex;
		align-items: center;
	}
}
.sp-container .main-mail-header .btn-group button {
	font-size: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	line-height: 0;
	padding: 0;
	position: relative;
	z-index: 1;
	border-color: $gray-300;
	background-color: $white;
}
.main-mail-header .btn-group {
	.btn:hover, .sp-container button:hover {
		color: $gray-900;
		background-color: $gray-100;
	}
}
.sp-container .main-mail-header .btn-group button:hover {
	color: $gray-900;
	background-color: $gray-100;
}
.main-mail-header .btn-group {
	.btn:focus, .sp-container button:focus {
		color: $gray-900;
		background-color: $gray-100;
	}
}
.sp-container .main-mail-header .btn-group button:focus {
	color: $gray-900;
	background-color: $gray-100;
}
.main-mail-header .btn-group {
	.btn.disabled, .sp-container button.disabled {
		background-color: $white;
		color: $gray-300;
		border-color: $border;
		z-index: 0;
	}
}
.sp-container .main-mail-header .btn-group button.disabled {
	background-color: $white;
	color: $gray-300;
	border-color: $border;
	z-index: 0;
}
.main-mail-header .btn-group {
	.btn.disabled:focus, .sp-container button.disabled:focus {
		box-shadow: none;
	}
}
.sp-container .main-mail-header .btn-group button.disabled:focus {
	box-shadow: none;
}
.main-mail-header .btn-group {
	.btn.disabled:active, .sp-container button.disabled:active {
		box-shadow: none;
	}
}
.sp-container .main-mail-header .btn-group button.disabled:active {
	box-shadow: none;
}
.main-mail-header .btn-group {
	.btn+.btn, .sp-container button+.btn {
		margin-left: -2px;
	}
}
.sp-container .main-mail-header .btn-group button+.btn, .main-mail-header .btn-group .sp-container .btn+button, .sp-container .main-mail-header .btn-group .btn+button, .main-mail-header .btn-group .sp-container button+button, .sp-container .main-mail-header .btn-group button+button {
	margin-left: -2px;
}
.main-mail-options {
	padding: 5px 10px 5px 20px;
	border: 1px solid $border;
	display: none;
	align-items: center;
	justify-content: flex-end;
	.btn, .sp-container button {
		font-size: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		background-color: transparent;
	}
}
@media (min-width: 992px) {
	.main-mail-options {
		padding-left: 25px;
		display: flex;
		justify-content: space-between;
	}
}
.sp-container .main-mail-options button {
	font-size: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	background-color: transparent;
}
.main-mail-options {
	.btn:hover, .sp-container button:hover {
		background-color: $gray-200;
	}
}
.sp-container .main-mail-options button:hover {
	background-color: $gray-200;
}
.main-mail-options {
	.btn:focus, .sp-container button:focus {
		background-color: $gray-200;
	}
}
.sp-container .main-mail-options button:focus {
	background-color: $gray-200;
}
.main-mail-options {
	.btn i, .sp-container button i {
		line-height: 0;
	}
}
.sp-container .main-mail-options button i {
	line-height: 0;
}
.main-mail-options {
	.btn i.typcn, .sp-container button i.typcn {
		line-height: .75;
	}
}
.sp-container .main-mail-options button i.typcn {
	line-height: .75;
}
.main-mail-options {
	.btn.disabled, .sp-container button.disabled {
		background-color: transparent;
		color: $gray-600;
	}
}
.sp-container .main-mail-options button.disabled {
	background-color: transparent;
	color: $gray-600;
}
.main-mail-list {
	border: 1px solid $border;
}
@media (min-width: 992px) {
	.main-mail-list {
		border-top-width: 0;
	}
}
.main-mail-item {
	padding: 10px 15px;
	border-top: 1px solid $border;
	border-bottom: 0px solid $border;
	background-color: $white;
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	+.main-mail-item {
		margin-top: -1px;
	}
	&:first-child {
		border-top-width: 0 !important;
	}
	.main-img-user, .main-avatar {
		flex-shrink: 0;
		margin-right: 15px;
	}
	&:hover, &:focus {
		background-color: $gray-100;
	}
	&.unread {
		background-color: $gray-100;
	}
	&.selected {
		background-color: white;
	}
}
@media (min-width: 576px) {
	.main-mail-item {
		padding: 10px 20px;
	}
}
@media (min-width: 992px) {
	.main-mail-item {
		width: auto;
		padding: 15px 25px;
	}
}
.main-mail-checkbox {
	margin-right: 15px;
	display: none;
}
@media (min-width: 992px) {
	.main-mail-checkbox {
		display: block;
	}
}
.main-mail-star {
	margin-right: 15px;
	font-size: 18px;
	line-height: .9;
	color: $gray-300;
	position: absolute;
	bottom: 10px;
	right: 0;
	&.active {
		color: $warning;
	}
}
@media (min-width: 992px) {
	.main-mail-star {
		position: relative;
		bottom: auto;
		top: 2px;
	}
}
.main-mail-body {
	width: calc(100% - 80px);
	cursor: pointer;
}
@media (min-width: 992px) {
	.main-mail-body {
		max-width: 460px;
		margin-right: 15px;
		flex: 1;
	}
}
@media (min-width: 1200px) {
	.main-mail-body {
		max-width: 640px;
	}
}
.main-mail-from {
	font-size: 13px;
}
@media (min-width: 576px) {
	.main-mail-subject {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
}
.main-mail-subject {
	strong {
		font-weight: 700;
		font-size: 14px;
		color: $gray-900;
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
	span {
		font-size: 13px;
		color: $gray-700;
		display: none;
	}
}
@media (min-width: 576px) {
	.main-mail-subject strong {
		display: inline;
		width: auto;
		white-space: normal;
		text-overflow: inherit;
		overflow: visible;
	}
}
@media (min-width: 576px) {
	.main-mail-subject span {
		display: inline;
	}
}
.main-mail-attachment {
	margin-right: 15px;
	font-size: 21px;
	line-height: .9;
	display: none;
}
@media (min-width: 992px) {
	.main-mail-attachment {
		display: block;
		position: absolute;
		right: 63px;
		top: 8px;
	}
}
.main-mail-date {
	font-size: 11px;
	position: absolute;
	top: 12px;
	right: 15px;
	color: $gray-700;
	margin-left: auto;
}
@media (min-width: 992px) {
	.main-mail-date {
		position: absolute;
		font-size: 13px;
		top: 8px;
	}
}
.main-mail-compose {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(28, 39, 60, 0.5);
	z-index: 1000;
	display: none;
	>div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
}
@media (min-width: 992px) {
	.main-mail-compose .container {
		padding: 0;
	}
}
.main-mail-compose-box {
	box-shadow: 0 0 30px rgba(28, 39, 60, 0.2);
	border-radius: 3px;
}
.main-mail-compose-header {
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $gray-900;
	color: $white;
	font-size: 14px;
	font-weight: 500;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	.nav-link {
		color: $white-3;
		font-size: 14px;
		line-height: 1;
		padding: 0;
		transition: all 0.2s ease-in-out;
		&:hover, &:focus {
			color: $white;
		}
		+.nav-link {
			margin-left: 15px;
		}
		&:nth-child(2) {
			display: none;
		}
	}
}
@media (min-width: 992px) {
	.main-mail-compose-header {
		padding: 20px 25px;
	}
}
@media (prefers-reduced-motion: reduce) {
	.main-mail-compose-header .nav-link {
		transition: none;
	}
}
@media (min-width: 768px) {
	.main-mail-compose-header .nav-link:nth-child(2) {
		display: block;
	}
}
.main-mail-compose-body {
	background-color: $white;
	padding: 20px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
	.form-group {
		display: flex;
		align-items: center;
		>div {
			flex: 1;
			margin-left: 10px;
		}
		.form-label {
			margin: 0;
			color: $gray-900;
		}
		.form-control {
			border-width: 0;
			border-radius: 0;
			padding: 0;
			&:focus {
				box-shadow: none !important;
			}
		}
		+.form-group {
			border-top: 1px dotted $gray-300;
			padding-top: 1rem;
		}
		&:last-child {
			display: block;
			.btn, .sp-container button {
				width: 100%;
				margin-top: 15px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}
@media (min-width: 992px) {
	.main-mail-compose-body {
		padding: 25px;
	}
}
@media (min-width: 576px) {
	.main-mail-compose-body .form-group:last-child {
		display: flex;
		justify-content: space-between;
	}
}
.sp-container .main-mail-compose-body .form-group:last-child button {
	width: 100%;
	margin-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
}
@media (min-width: 576px) {
	.main-mail-compose-body .form-group:last-child {
		.btn, .sp-container button {
			width: auto;
			margin-top: 0;
		}
	}
	.sp-container .main-mail-compose-body .form-group:last-child button {
		width: auto;
		margin-top: 0;
	}
}
.main-mail-compose-body .form-group .nav-link {
	padding: 0;
	font-size: 18px;
	line-height: 0;
	color: $default-color;
	position: relative;
	transition: all 0.2s ease-in-out;
	&:hover, &:focus {
		color: $gray-900;
	}
	+.nav-link {
		margin-left: 15px;
	}
}
@media (prefers-reduced-motion: reduce) {
	.main-mail-compose-body .form-group .nav-link {
		transition: none;
	}
}
.main-mail-compose-compress, .main-mail-compose-minimize {
	top: auto;
	left: auto;
	bottom: 0;
	right: 30px;
	width: 560px;
	height: auto;
	background-color: transparent;
}
.main-mail-compose-compress .container {
	max-width: none;
	padding: 0;
}
.main-mail-compose-minimize {
	.container {
		max-width: none;
		padding: 0;
	}
	.main-mail-compose-body {
		display: none;
	}
}
/* ###### 7.7 Mail Two  ###### */

.main-mail-two {
	.main-content {
		display: flex;
		flex-direction: column;
	}
	.main-header {
		width: 100%;
		border-bottom: 1px solid $border;
	}
	.main-footer {
		width: 100%;
	}
	.main-header-menu-icon {
		margin-right: 0;
	}
	.main-content-body {
		display: flex;
		padding: 0;
	}
}
.main-mail-left {
	background-color: #f9f9f9;
	width: 240px;
	border-right: 1px solid$gray-400;
	padding: 20px;
	display: none;
	.btn-compose {
		display: block;
		margin-bottom: 20px;
	}
	.main-mail-menu {
		padding-right: 0;
	}
}
@media (min-width: 1200px) {
	.main-mail-left {
		display: block;
	}
}
.main-mail-content {
	background-color: #fcfcfc;
	flex: 1;
	max-width: 100vw (-480px);
	.main-mail-header {
		margin-bottom: 0;
		padding: 20px;
	}
	.main-mail-body {
		max-width: 590px;
	}
}
.main-nav-line-email {
	border-bottom: 1px solid $border;
	padding-bottom: 0px;
	flex-shrink: 0;
	flex-direction: row;
	padding-left: 20px;
	.nav-link {
		font-size: 13px;
		font-weight: 500;
		color: $gray-800;
		text-transform: uppercase;
		&:hover, &:focus, &:active {
			outline: none;
		}
		i {
			color: $gray-700;
		}
		+.nav-link {
			margin-top: 0;
			margin-left: 30px;
		}
		&.active {
			&::before {
				top: auto;
				left: 0;
				right: 0;
				width: auto;
				bottom: 0px;
			}
		}
	}
}
@media (max-width: 992px) {
	.main-nav-line-email .nav-link+.nav-link {
		margin-left: 0 !important;
	}
}