/* Checkbox */

.ckbox {
	font-weight: normal;
	position: relative;
	display: block;
	line-height: 1;
	margin-bottom: 0;
	span {
		padding-left: 15px;
		&:empty {
			float: left;
		}
		&:before, &:after {
			line-height: 18px;
			position: absolute;
		}
		&:before {
			content: '';
			width: 16px;
			height: 16px;
			background-color: $white;
			border: 1px solid $gray-300;
			border-radius: 3px;
			top: 1px;
			left: 0;
		}
		&:after {
			top: 1px;
			left: 0;
			width: 16px;
			height: 16px;
			content: '';
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
			background-size: 65%;
			background-repeat: no-repeat;
			background-position: 55% 45%;			
			line-height: 17px;
			display: none;
			border-radius: 3px;
		}
		&:empty {
			padding-left: 0;
			width: 3px;
		}
	}
	input[type='checkbox'] {
		opacity: 0;
		margin: 0;
		&:checked+span:after {
			display: block;
		}
		&[disabled]+span {
			opacity: .75;
			&:before, &:after {
				opacity: .75;
			}
		}
	}
}
.ckbox-inline {
	display: inline-block;
}
.custom-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
}
.custom-switch-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.custom-switches-stacked {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	.custom-switch {
		margin-bottom: .5rem;
	}
}
.custom-switch-indicator {
	display: inline-block;
	height: 1.25rem;
	width: 2.25rem;
	background: #e9ecef;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid #eff0f6;
	transition: .3s border-color, .3s background-color;
	&:before {
		content: '';
		position: absolute;
		height: calc(1.25rem - 4px);
		width: calc(1.25rem - 4px);
		top: 1px;
		left: 1px;
		background: #fff;
		border-radius: 50%;
		transition: .3s left;
		box-shadow: none;
	}
}
.custom-switch-input:checked~.custom-switch-indicator:before {
	left: calc(0.95rem + 1px);
}
.custom-switch-indicator-lg {
	display: inline-block;
	height: 1.5rem;
	width: 3rem;
	background: $gray-100;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid $border;
	transition: .3s border-color, .3s background-color;
	&:before {
		content: '';
		position: absolute;
		height: calc(1.5rem - 4px);
		width: calc(1.5rem - 4px);
		top: 1px;
		left: 1px;
		background: $white;
		border-radius: 50%;
		transition: .3s left;
		box-shadow: none;
	}
}
.custom-switch-input:checked~.custom-switch-indicator-lg:before {
	left: calc(1.46rem + 1px);
}
.custom-switch-indicator-xl {
	display: inline-block;
	height: 2rem;
	width: 4rem;
	background: $gray-100;
	border-radius: 50px;
	position: relative;
	vertical-align: bottom;
	border: 1px solid $border;
	transition: .3s border-color, .3s background-color;
	&:before {
		content: '';
		position: absolute;
		height: calc(2rem - 4px);
		width: calc(2rem - 4px);
		top: 1px;
		left: 1px;
		background: $white;
		border-radius: 50%;
		transition: .3s left;
		box-shadow: none;
	}
}
.custom-switch-input:checked~.custom-switch-indicator-xl:before {
	left: calc(2rem + 1px);
}
.custom-square {
	border-radius: 0px !important;
	&:before {
		border-radius: 0px !important;
	}
}
.custom-radius {
	border-radius: 4px !important;
	&:before {
		border-radius: 4px !important;
	}
}
.custom-switch-description {
	margin-left: .5rem;
	color: #6e7687;
	transition: .3s color;
}
.custom-switch-input:checked~.custom-switch-description {
	color: #495057;
}