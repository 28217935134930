.custom-control {
	position: relative;
	display: block;
	min-height: 1.8rem;
	padding-left: 1.5rem;
}
.custom-control-inline {
	display: inline-flex;
	margin-right: 1rem;
}
.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:focus {
		~.custom-control-label::before {
			box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
		}
		&:not(:checked)~.custom-control-label::before {
			border-color: #b3c6ff;
		}
	}
	&:not(:disabled):active~.custom-control-label::before {
		color: $white;
		background-color: #e6ecff;
		border-color: #e6ecff;
	}
	&:disabled~.custom-control-label {
		&::before {
			background-color: $gray-100;
		}
	}
}
.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	&::before {
		position: absolute;
		top: 0.15625rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		background-color: $white;
		border: $border solid 1px;
	}
	&::after {
		position: absolute;
		top: 0.15625rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background: no-repeat 50% / 50% 50%;
	}
}
.custom-control-label-lg::before, .custom-control-label-lg::after {
	top: -0.35rem !important;
	min-height: auto;
	width: 1.8rem !important;
	height: 1.8rem !important;
	left: -1.95rem;
}
.custom-control-label-md::before, .custom-control-label-md::after {
	top: -0.3rem !important;
	width: 1.5rem !important;
	height: 1.5rem !important;
	min-height: auto;
	left: -2rem;
}
.custom-checkbox {
	.custom-control-label::before {
		border-radius: 3px;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
		}
		&:indeterminate~.custom-control-label {
			&::after {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
			}
		}
		&:disabled {
			&:checked~.custom-control-label::before, &:indeterminate~.custom-control-label::before {
				opacity:0.5;
			}
		}
	}
}
.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
		}
		&:disabled:checked~.custom-control-label::before {
			opacity:0.5;
		}
	}
}
.custom-switch {
	padding-left: 2.25rem;
	.custom-control-label {
		&::before {
			left: -2.25rem;
			width: 1.75rem;
			pointer-events: all;
			border-radius: 0.5rem;
		}
		&::after {
			top: calc(0.15625rem + 2px);
			left: calc(-2.25rem + 2px);
			width: calc(1rem - 4px);
			height: calc(1rem - 4px);
			background-color: $gray-500;
			border-radius: 0.5rem;
			transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-color: $white;
			transform: translateX(0.75rem);
		}
		&:disabled:checked~.custom-control-label::before {
			background-color: rgba(82, 103, 224, 0.5);
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.custom-switch .custom-control-label::after {
		transition: none;
	}
}
.custom-select {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: $gray-700;
	vertical-align: middle;
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 3px;
	appearance: none;
	&:focus {
		border-color: #b3c6ff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
		&::-ms-value {
			color: $gray-700;
			background-color: $white;
		}
	}
	&[multiple], &[size]:not([size="1"]) {
		height: auto;
		padding-right: 0.75rem;
		background-image: none;
	}
	&:disabled {
		color: $gray-600;
		background-color: $gray-200;
	}
	&::-ms-expand {
		display: none;
	}
}
.custom-select-sm {
	height: calc(1.5em + 0.5rem + 2px);
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.76562rem;
}
.custom-select-lg {
	height: calc(1.5em + 1rem + 2px);
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.09375rem;
}
.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	margin-bottom: 0;
}
.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	margin: 0;
	opacity: 0;
	&:focus~.custom-file-label {
		border-color: $border;
		box-shadow: none;
		outline: 0 !important;
	}
	&:disabled~.custom-file-label {
		background-color: $gray-200;
	}
	&:lang(en)~.custom-file-label::after {
		content: "Browse";
	}
	~.custom-file-label[data-browse]::after {
		content: attr(data-browse);
	}
}
.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-weight: 400;
	line-height: 1.5;
	color: $gray-700;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 3px;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(1.5em + 0.75rem);
		padding: 0.375rem 0.75rem;
		line-height: 1.5;
		color: $white;
		content: "Browse";		
		border-left: inherit;
		border-radius: 0 3px 3px 0;
	}
}
.custom-range {
	width: 100%;
	height: calc(1rem + 0.4rem);
	padding: 0;
	background-color: transparent;
	appearance: none;
	&:focus {
		outline: none;
		&::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
			box-shadow: 0 0 0 1px $white, 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
		}
	}
	&::-moz-focus-outer {
		border: 0;
	}
	&::-webkit-slider-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: -0.25rem;
		border: 0;
		border-radius: 1rem;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		appearance: none;
		&:active {
			background-color: #e6ecff;
		}
	}
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: $gray-300;
		border-color: transparent;
		border-radius: 1rem;
	}
	&::-moz-range-thumb {
		width: 1rem;
		height: 1rem;
		border: 0;
		border-radius: 1rem;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		appearance: none;
		&:active {
			background-color: #e6ecff;
		}
	}
	&::-moz-range-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: $gray-300;
		border-color: transparent;
		border-radius: 1rem;
	}
	&::-ms-thumb {
		width: 1rem;
		height: 1rem;
		margin-top: 0;
		margin-right: 0.2rem;
		margin-left: 0.2rem;		
		border: 0;
		border-radius: 1rem;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		appearance: none;
		&:active {
			background-color: #e6ecff;
		}
	}
	&::-ms-track {
		width: 100%;
		height: 0.5rem;
		color: transparent;
		cursor: pointer;
		background-color: transparent;
		border-color: transparent;
		border-width: 0.5rem;
	}
	&::-ms-fill-lower {
		background-color: $gray-300;
		border-radius: 1rem;
	}
	&::-ms-fill-upper {
		margin-right: 15px;
		background-color: $gray-300;
		border-radius: 1rem;
	}
	&:disabled {
		&::-webkit-slider-thumb {
			background-color: $gray-500;
		}
		&::-webkit-slider-runnable-track {
			cursor: default;
		}
		&::-moz-range-thumb {
			background-color: $gray-500;
		}
		&::-moz-range-track {
			cursor: default;
		}
		&::-ms-thumb {
			background-color: $gray-500;
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.custom-range::-webkit-slider-thumb {
		transition: none;
	}
}
@media (prefers-reduced-motion: reduce) {
	.custom-range::-moz-range-thumb {
		transition: none;
	}
}
@media (prefers-reduced-motion: reduce) {
	.custom-range::-ms-thumb {
		transition: none;
	}
}
.custom-control-label::before, .custom-file-label, .custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.custom-control-label::before, .custom-file-label, .custom-select {
		transition: none;
	}
}
.custom-file, .custom-file-input {
	height: 38px;
}
.custom-file-label {
	height: 38px;
	line-height: 1.8;
	&::after {
		line-height: 1.8;
		height: auto;
	}
}
.custom-select {
	background: none;
}