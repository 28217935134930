.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background: $white !important;
	background-clip: border-box;
	border-radius: 10px;
	border: 0px solid $border!important;
	margin-bottom: 1.3rem;
	box-shadow: $shadow;
	> {
		hr {
			margin-right: 0;
			margin-left: 0;
		}
		.list-group {
			&:first-child .list-group-item:first-child {
				border-top-left-radius: 3px;
				border-top-right-radius: 3px;
			}
			&:last-child .list-group-item:last-child {
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
	}
}
.card-body {
	flex: 1 1 auto;
	padding: 1.75rem;
}
.card-title {
	margin-bottom: 0.75rem;
}
.card-subtitle {
	margin-top: -0.375rem;
	margin-bottom: 0;
}
.card-text:last-child {
	margin-bottom: 0;
}
.card-link {
	&:hover {
		text-decoration: none;
	}
	+.card-link {
		margin-left: 1.25rem;
	}
}
.card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgb(255, 255, 255);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	&:first-child {
		border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
	}
	+.list-group .list-group-item:first-child {
		border-top: 0;
	}
}
.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: transparent;
	border-top: 1px solid rgba(0, 0, 0, 0.125);
	&:last-child {
		border-radius: 0 0 calc(10px - 1px) calc(10px - 1px);
	}
}
.card-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0;
}
.card-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
	border-radius: 5px;
}
.card-img {
	width: 100%;
	border-radius: calc(5px - 1px);
}
.card-img-top {
	width: 100%;
	border-top-left-radius: calc(5px - 1px);
	border-top-right-radius: calc(5px - 1px);
}
.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(5px - 1px);
	border-bottom-left-radius: calc(5px - 1px);
}
.card-deck {
	display: flex;
	flex-direction: column;
	.card {
		margin-bottom: 15px;
	}
}
@media (min-width: 576px) {
	.card-deck {
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;
		.card {
			display: flex;
			flex: 1 0 0%;
			flex-direction: column;
			margin-right: 15px;
			margin-bottom: 0;
			margin-left: 15px;
		}
	}
}
.card-group {
	display: flex;
	flex-direction: column;
	>.card {
		margin-bottom: 15px;
	}
}
@media (min-width: 576px) {
	.card-group {
		flex-flow: row wrap;
		>.card {
			flex: 1 0 0%;
			margin-bottom: 0;
			+.card {
				margin-left: 0;
				border-left: 0;
			}
			&:not(:last-child) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				.card-img-top, .card-header {
					border-top-right-radius: 0;
				}
				.card-img-bottom, .card-footer {
					border-bottom-right-radius: 0;
				}
			}
			&:not(:first-child) {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				.card-img-top, .card-header {
					border-top-left-radius: 0;
				}
				.card-img-bottom, .card-footer {
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}
.card-columns .card {
	margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
	.card-columns {
		column-count: 3;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;
		.card {
			display: inline-block;
			width: 100%;
		}
	}
}
.card {
	&.text-white .card-title, &.tx-white .card-title {
		color: $white;
	}
}
.card-header, .card-footer {
	position: relative;
	border-color: $border;
	padding-left: 1.75rem;
	padding-right: 1.75rem;
}
.card-header {
	border-bottom: 0;
	&:first-child {
		border-radius: 10px 10px 0 0;
	}
	.card-title {
		margin-bottom: 0;
	}
}
.card-header-tab {
	border-bottom: 0;
	padding: 0;
}
.card-title {
	font-weight: 700;
	color: $default-color;
	font-size: 14px;
	text-transform: uppercase;
}
.card-item-desc .card-item-desc-1 dt, dd {
	display: inline-block;
}
.card-item-desc {
	.card-item-desc-1 {
		dt {
			font-weight: 500;
			font-size: 13px;
		}
		dd {
			font-size: 13px;
			color: #adaeb7;
			margin-bottom: 0;
		}
	}
	dl {
		margin-bottom: 0;
	}
}
.card .card-header .card-header-right .card-option {
	width: 35px;
	height: 20px;
	overflow: hidden;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	li {
		display: inline-block;
	}
}
.card-category {
	font-size: 16px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin: 0 0 .5rem;
	padding: 10px;
	border-radius: 3px;
	background: #eff2f6;
}
.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div {
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 700;
	color: $gray-600;
	display: none;
}
.card-minimal-two {
	.nav-pills .nav-link.active {
		border-radius: 2px;
	}
	.nav-link {
		display: block;
		padding: 8px 30px;
		margin: 2px;
		background-color: $gray-200;
	}
}
.card-body-top {
	a {
		color: $gray-900;
		font-weight: 700;
		width: 20px;
		text-align: right;
		display: inline-block;
	}
	top: 63px;
	left: -5px;
	position: absolute;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	z-index: 10;
}
.card-minimal-four .card-footer .nav-link {
	flex: 1;
	display: block;
	text-align: center;
	background-color: $gray-200;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
}
.card-progress {
	display: flex;
	align-items: center;
	.progress {
		height: 3px;
		flex: 1;
		margin: 0 5px;
	}
}
.card-status {
	position: absolute;
	top: -1px;
	left: 0px;
	right: 0px;
	height: 3px;
	border-radius: 7px 7px 0 0;
	background: rgba(0, 40, 100, 0.12);
}
.card-status-left {
	right: auto;
	bottom: 0;
	height: auto;
	width: 3px;
	border-radius: 7px 0 0 7px;
}
/**Card icon*/