/* ###### 5.5 List   ###### */

.main-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	+.main-list-item {
		border-top: 1px solid $gray-200;
	}
	&:last-child {
		padding-bottom: 0;
	}
	h6 {
		margin-bottom: 2px;
		font-weight: 600;
	}
	>div {
		&:first-child h6 {
			color: $gray-900;
		}
		span {
			color: $gray-600;
			font-size: 12px;
		}
		&:last-child {
			text-align: right;
			h6 {
				line-height: 1;
			}
		}
	}
}
.list-group-style {
	padding: 0;
	list-style: none;
	li {
		line-height: 28px;
	}
}
.list-group-style2 {
	padding-left: 20px;
	li {
		line-height: 28px;
	}
}
.list-style-circle {
	list-style: circle;
}
.list-style-square {
	list-style: square;
}
.list-style-roman {
	list-style: roman;
}
.list-style-alpha {
	list-style-type: lower-alpha;
	padding-left: 40px;
}