.main-header-profile .main-img-user {
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 15px;
		width: 6px;
		height: 6px;
		background-color: #17b86a;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
		border-radius: 100%;
		bottom: 3px;
		display: block !important;
	}
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	text-align: center;
}
.main-profile-menu .profile-user img {
	width: 37px;
	height: 37px;
	border-radius: 50%;
	margin-left: 0;
	width: 37px;
	height: 37px;
	border-radius: 50%;
}
.main-profile-overview .main-img-user::after {
	display: none !important;
}
.main-profile-overview {
	.card-body.border {
		border-radius: 10px;
	}
}
/* ###### 7.8 Profile  ###### */

.main-content-profile {
	flex: 1;
}
@media (max-width: 991.98px) {
	.main-content-profile {
		.container, .container-fluid {
			display: block;
		}
	}
}
.main-content-left-profile {
	padding-left: 0;
	padding-right: 0;
	display: block;
	border-bottom: 1px solid $border;
	padding-bottom: 25px;
	width: auto;
}
@media (min-width: 992px) {
	.main-content-left-profile {
		width: 270px;
		padding-right: 20px;
		padding-bottom: 0;
		border-right: 1px solid $border;
		border-bottom: 0;
	}
}
@media (min-width: 1200px) {
	.main-content-left-profile {
		padding-right: 25px;
	}
}
.main-profile-overview {
	.profile-user {
		width: 120px;
		height: 120px;
		margin-bottom: 20px;
	}
	.btn-icon-list {
		.btn, .sp-container button {
			border-radius: 100%;
		}
	}
}
.sp-container .main-profile-overview .btn-icon-list button {
	border-radius: 100%;
}
.main-profile-name {
	color: $gray-900;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 2px;
}
.main-profile-name-text {
	color: $gray-600;
	font-size: 13px;
	margin-bottom: 0;
}
.main-profile-bio {
	font-size: 13px;
	margin-bottom: 20px;
}
.main-profile-social-list {
	.media {
		align-items: center;
		+.media {
			margin-top: 20px;
		}
	}
	.media-icon {
		font-size: 34px;
		width: 30px;
		line-height: 0;
	}
	.media-body {
		margin-left: 20px;
		span {
			display: block;
			font-size: 12px;
		}
		a {
			font-size: 13px;
		}
	}
}
.main-content-body-profile {
	.nav {
		flex-direction: column;
		padding: 20px 20px 20px 0;
		border-bottom: 1px solid $border;
		margin-bottom: 1.3rem;
	}
	.main-nav-line .nav-link {
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 500;
		color: $gray-800;
		&.active {
			font-weight: 700;
		}
	}
}
@media (min-width: 576px) {
	.main-content-body-profile .nav {
		flex-direction: row;
		align-items: center;
	}
}
@media (min-width: 992px) {
	.main-content-body-profile .nav {
		padding-left: 20px;
	}
}
@media (min-width: 1200px) {
	.main-content-body-profile .nav {
		padding-left: 25px;
	}
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.main-content-body-profile .main-nav-line .nav-link.active::before {
		width: auto;
		top: auto;
		left: 0;
		right: 0;
		height: 2px;
	}
}
@media (min-width: 576px) and (max-width: 767.98px) {
	.main-content-body-profile .main-nav-line .nav-link+.nav-link {
		margin-top: 0;
		margin-left: 20px;
	}
}
.main-profile-body {
	padding: 15px 0 0;
}
@media (min-width: 576px) {
	.main-profile-body {
		padding-top: 20px;
	}
}
@media (min-width: 992px) {
	.main-profile-body {
		padding: 25px 0 0 20px;
	}
}
@media (min-width: 1200px) {
	.main-profile-body {
		padding-left: 25px;
	}
}
.main-profile-view-chart {
	position: relative;
	width: calc(100% - 10px);
	height: 200px;
}
@media (min-width: 375px) {
	.main-profile-view-chart {
		width: 100%;
	}
}
@media (min-width: 576px) {
	.main-profile-view-chart {
		height: 250px;
	}
}
.main-profile-view-info {
	position: absolute;
	top: 0;
	left: 0;
	h6 {
		font-size: 32px;
		font-weight: 500;
		color: $gray-900;
		margin-bottom: 0;
	}
	span {
		font-size: 12px;
		color: $pink;
		margin-left: 5px;
	}
	p {
		font-size: 13px;
		margin-bottom: 0;
	}
}
.main-traffic-detail-item {
	>div:first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		margin-bottom: 7px;
		>span {
			&:first-child {
				color: $gray-600;
			}
			&:last-child {
				font-size: 11px;
				font-weight: 700;
				color: $gray-900;
				span {
					color: $gray-600;
					font-weight: 400;
				}
			}
		}
	}
	+.main-traffic-detail-item {
		margin-top: 25px;
	}
	.progress {
		height: 8px;
	}
}
.main-profile-work-list {
	.media+.media {
		margin-top: 25px;
	}
	.media-logo {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		color: $white;
		font-size: 21px;
		position: relative;
		top: 2px;
		border-radius: 100%;
	}
	.media-body {
		margin-left: 20px;
		h6 {
			color: $gray-900;
			font-weight: 500;
			margin-bottom: 2px;
		}
		span {
			display: block;
			margin-bottom: 5px;
		}
		p {
			margin-bottom: 0;
			font-size: 12px;
			color: $gray-600;
		}
	}
}
.main-profile-contact-list {
	.media {
		align-items: center;
		+.media {
			margin-top: 25px;
		}
	}
	.media-icon {
		line-height: 0;
		font-size: 36px;
		width: 35px;
		text-align: center;
	}
	.media-body {
		margin-left: 25px;
		span {
			font-size: 12px;
			color: $gray-600;
			display: block;
			line-height: 1.3;
		}
		div {
			font-weight: 500;
			color: $gray-900;
		}
	}
}
@media (max-width: 1134px) {
	.main-profile-body .media.profile-footer {
		display: block !important;
		.media-body {
			padding: 3px 0 15px 0;
		}
	}
}
@media (max-width: 578px) {
	.main-profile-menu .profile-user img {
		margin-left: 0;
	}
}
.main-profile-work-list .media-logo, .main-profile-contact-list .media-icon, .main-profile-social-list .media-icon {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	color: $white;
	font-size: 21px;
	position: relative;
	top: 2px;
	border-radius: 100%;
}
.profile-user .profile-edit {
	position: absolute;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	line-height: 30px;
	right: 0;
	background: #d5d4e0;
	margin: 0 auto;
	text-align: center;
}
.main-profile-body .card-header {
	padding-top: 0;
	padding-left: 0;
	padding: 0;
}
.drop-down-profile {
	position: relative;
}
.profile-footer a {
	width: 34px;
	height: 34px;
	background: #efeff5;
	color: $black;
	margin: 1px auto;
	text-align: center;
	line-height: 34px;
	display: inline-block;
	border-radius: 50%;
	font-size: 12px;
}
.drop-down-profile span.assigned-task {
	position: absolute;
	font-size: 12px;
	width: 18px;
	height: 18px;
	line-height: 18px;
	color: $white;
	top: 0;
	margin: 0 auto;
	text-align: center;
	border-radius: 50%;
	right: 0;
}
@media (max-width: 575px) {
	.main-content-body-profile .main-nav-line .nav-link.active::before {
		left: 0px;
	}
}
@media (max-width: 767.98px) {
	.main-profile-menu .profile-user img {
		margin-left: 0 !important;
	}
}
.profile-name {
	margin-top: 8px;
}
.profile-banner {
	position: relative;
	.profile-content {
		position: absolute;
		bottom: -65px;
		left: 30px;
	}
}
.profile-nav-line {
	border: 1px solid $border;
	margin-bottom: 20px;
	border-radius: 3px;
	.nav-link {
		padding: 13px 20px 10px 20px;
		display: block;
		&.active::before {
			top: auto;
			bottom: 0;
			width: 80%;
			left: 10%;
		}
	}
}
.profile-buttons {
	position: absolute;
	top: 10px;
	right: 10px;
}
@media (max-width:568px) {
	.profile-banner .profile-content {
		left: 10px
	}
}