.main-toggle {
	width: 60px;
	height: 25px;
	background-color: $gray-200;
	padding: 2px;
	position: relative;
	overflow: hidden;
	color: $gray-900;
	border-radius: 3px;
	span {
		position: absolute;
		top: 3px;
		bottom: 3px;
		left: 3px;
		display: block;
		width: 20px;
		background-color: $white;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		border-radius: 3px;
		&::before, &::after {
			position: absolute;
			font-size: 10px;
			font-weight: 500;
			letter-spacing: .5px;
			text-transform: uppercase;
			color: $white;
			top: 2px;
			line-height: 1.38;
		}
		&::before {
			content: 'on';
			left: -25px;
		}
		&::after {
			content: 'off';
			right: -29px;
		}
	}
	&.on {
		span {
			left: 37px;
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.main-toggle span {
		transition: none;
	}
}
.main-toggle-success.on {
	background-color: $success;
}
.main-toggle-dark.on {
	background-color: $gray-900;
}
.main-toggle-group-demo {
	display: flex;
	.main-toggle+.main-toggle {
		margin-left: 10px;
	}
}