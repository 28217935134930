/* ###### 4.9 Perfect Scrollbar ###### */

.ps {
	overflow: hidden;
	>.ps__rail-y {
		width: 2px;
		background-color: $white-5;
		z-index: 1;
		position: absolute;
		left: auto !important;
		right: 0;
		opacity: 0;
		transition: opacity 0.2s;
		>.ps__thumb-y {
			position: absolute;
			border-radius: 0;
			width: 2px;
			left: 4px;
			background-color: $gray-500;
		}
	}
	&.ps--active-y {
		&:hover>.ps__rail-y, &:focus>.ps__rail-y {
			opacity: 1;
		}
	}
}
@media (prefers-reduced-motion: reduce) {
	.ps>.ps__rail-y {
		transition: none;
	}
}